<template>
  <div class="home">

    <breadcrumb :items="breadcrumb" />

    <v-row justify="end">
      <v-col>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Wyszukaj"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col style="text-align: right">
        <v-btn
          color="secondary_dark"
          dark
          depressed
          tile
          to="/uzytkownik-nowy"
        >
          Dodaj nowego
        </v-btn>
      </v-col>
    </v-row>
    
    <v-data-table
      class="classic_table"
      :headers="headers"
      :items="list"
      :search="search"
      item-key="name"
      @click:row="userClick"
      :footer-props="{
        itemsPerPageText : 'Elementów na stronie',
        itemsPerPageAllText : 'Wszystkie',
        itemsPerPageOptions : [15,45,90,-1],
      }"
    >
      <template v-slot:no-data>Brak danych</template>
      <template v-slot:no-results>Brak wyników</template>
      <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
        {{ pageStart }}-{{ pageStop }} z {{ itemsLength}}
      </template>

    </v-data-table>
  </div>
</template>

<script>
export default {
  data: () => ({
    breadcrumb: [
      {
        text: 'Pulpit',
        disabled: false,
        to: '/panel',
      },
      {
        text: 'Lista użytkowników',
        disabled: true,
        to: '/uzytkownicy',
      },
    ],

    headers: [
      {
        text: 'ID',
        align: 'start',
        value: 'id',
      },
      { text: 'Nazwa Klienta', value: 'name' },
      { text: 'e-mail', value: 'email' },
      { text: 'Telefon', value: 'number' },
    ],

    list: [
      {
        id: 1,
        name: 'Nazwa Klienta',
        email: 'email@email.com',
        number: '12313131233'
      },
    ],
    
    search: ''
  }),
  methods: {
    userClick(value) {
      this.$router.push('/uzytkownik/'+value.id);
    }
  },
  mounted(){
    this.$store.commit('loader');
    this.$axios({url: this.$store.state.api +'/auth/user', data: {}, method: 'GET' })
      .then(resp => {
        this.list = resp.data;
        console.log(resp.data);
        this.$store.commit('loader_off');
      })
      .catch(err => {
        this.$store.commit('snackbar', {text: err.response.data.response, color: 'red', btn_color: 'black'})
      })
      .then (() => {
        this.$store.commit('loader_off');
      })
  }
};
</script>

<style lang="scss" scoped>
.classic_table {
  margin-top: 25px;
}
.paid {
  background-color: green;
  color: white;
  padding: 5px 10px;
  text-align: center;
}
.not_paid {
  background-color: red;
  color: white;
  padding: 5px;
  text-align: center;
}
</style>